import checkEmail from './api/checkEmail';
import createLoginToken from './api/createLoginToken';
import deleteCustomer from './api/deleteCustomer';
import getAuthParams from './api/getAuthParams';
import getCustomers from './api/getCustomers';
import getSessions from './api/getSessions';
import login from './api/login';
import loginWithToken from './api/loginWithToken';
import logout from './api/logout';
import recoverCustomer from './api/recoverCustomer';
import recoverPassword from './api/recoverPassword';
import removeAvatar from './api/removeAvatar';
import resendEmailChangeEmail from './api/resendEmailChangeEmail';
import resendSignupEmail from './api/resendSignupEmail';
import resetPassword from './api/resetPassword';
import revertEmailChange from './api/revertEmailChange';
import revokeSessions from './api/revokeSessions';
import sendDeleteCustomerEmail from './api/sendDeleteCustomerEmail';
import signup from './api/signup';
import unlinkAuthMethod from './api/unlinkAuthMethod';
import updateCustomer from './api/updateCustomer';
import verifyEmail from './api/verifyEmail';
import { getRecaptchaVersion, initRecaptchaV2 } from './authApi/reCaptcha';
import swapSessionToken from './graphql/swapSessionToken';
import { isModuleInited, setInited } from './internalValues/moduleStatus';
import { ILoginApi } from './LoginApi';
import BaseTokenStorage from './storage/BaseTokenStorage';
import InternalTokenStorage from './storage/InternalTokenStorage';
import {
  AuthProvider,
  CheckEmailInput,
  CheckEmailResult,
  CreateLoginTokenInput,
  CreateLoginTokenResult,
  DeleteCustomerInput,
  DeleteCustomerResult,
  GetAuthParamsInput,
  GetAuthParamsResult,
  GetSessionsInput,
  LoginInput,
  LoginWithTokenInput,
  LoginResult,
  LogoutResult,
  QueryResult,
  RecoverPasswordInput,
  RecoverPasswordResult,
  RemoveAvatarResult,
  ResendEmailChangeEmailInput,
  ResendEmailChangeEmailResult,
  ResendSignupEmailInput,
  ResendSignupEmailResult,
  ResetPasswordInput,
  ResetPasswordResult,
  RevertEmailInput,
  RevertEmailResult,
  RevokeSessionsInput,
  RevokeSessionsResult,
  SendDeleteCustomerEmailInput,
  SendDeleteCustomerEmailResult,
  SessionsResult,
  SignupInput,
  SignupResult,
  UnlinkResult,
  UpdateCustomerInput,
  UpdateCustomerResult,
  UploadAvatarResult,
  VerifyEmailInput,
  VerifyEmailResult,
} from './types';
import { ClientOptions, setOptions } from './util/clientOptions';
import { ENABLE_PIANO_TOKEN_SWAP, PIANO_USER_TOKEN_NAME, PIANO_USER_SESSION_TOKEN_NAME } from './util/config';
import { getCookie as getLocalCookie, setPianoCookie } from './util/cookie';

export interface IResolveAuthMethodResult {
  automaticallyLinked?: boolean;
  error?: string;
  signup?: (email: string) => Promise<boolean>;
  login?: () => Promise<boolean>;
}

export default class LoginApiNpm implements ILoginApi {
  protected tokenStorage: BaseTokenStorage;

  protected inited?: Promise<void>;

  #reCaptchaToken: Promise<string> | string | undefined;

  public version = 2;

  constructor(token?: string, options?: Partial<ClientOptions>) {
    setOptions(options);
    this.tokenStorage = new InternalTokenStorage(token);
    this.init();
  }

  public init() {
    this.inited = new Promise(async resolve => {
      await this.tokenStorage.init();

      setInited();
      resolve();
    });
  }

  public async checkEmail(params: CheckEmailInput): Promise<QueryResult<CheckEmailResult
  >> {
    await this.inited;

    return checkEmail(params);
  }

  public async login(params: LoginInput): Promise<QueryResult<LoginResult>> {
    await this.inited;

    return login(params, this.tokenStorage);
  }

  public async createLoginToken(params: CreateLoginTokenInput): Promise<QueryResult<CreateLoginTokenResult>> {
    await this.inited;

    return createLoginToken(params);
  }

  public async loginWithToken(params: LoginWithTokenInput): Promise<QueryResult<LoginResult>> {
    await this.inited;

    return loginWithToken(params, this.tokenStorage);
  }

  public async signup(
    params: SignupInput,
    logIn = false,
  ): Promise<QueryResult<SignupResult>> {
    await this.inited;

    return signup(this.tokenStorage, params, logIn);
  }

  public async logout(): Promise<QueryResult<LogoutResult>> {
    await this.inited;

    return logout(this.tokenStorage);
  }

  public async getCustomers(): Promise<QueryResult<any>> {
    await this.inited;

    return getCustomers(this.tokenStorage);
  }

  public async getAuthParams(
    input: GetAuthParamsInput,
  ): Promise<QueryResult<GetAuthParamsResult>> {
    await this.inited;

    return getAuthParams(input);
  }

  public async getSessions(
    params: GetSessionsInput,
  ): Promise<QueryResult<SessionsResult>> {
    await this.inited;

    return getSessions(params, this.tokenStorage);
  }

  public async revokeSessions(
    params: RevokeSessionsInput,
  ): Promise<QueryResult<RevokeSessionsResult>> {
    await this.inited;

    const result = await revokeSessions(params, this.tokenStorage);

    // log customer out if revoking was successful and also current session was revoked
    if (result.data?.success && !params.excludeCurrent) {
      await this.logout();
    }

    return result;
  }

  public async unlinkAuthMethod(
    params: AuthProvider,
  ): Promise<QueryResult<UnlinkResult>> {
    await this.inited;

    return unlinkAuthMethod(params, this.tokenStorage);
  }

  public async removeAvatar(): Promise<QueryResult<RemoveAvatarResult>> {
    await this.inited;

    return removeAvatar(this.tokenStorage);
  }

  public async recoverPassword(
    params: RecoverPasswordInput,
  ): Promise<QueryResult<RecoverPasswordResult>> {
    await this.inited;

    return recoverPassword(params);
  }

  public async resetPassword(
    params: ResetPasswordInput,
  ): Promise<QueryResult<ResetPasswordResult>> {
    await this.inited;

    return resetPassword(params);
  }

  public async resendEmailChangeEmail(
    params: ResendEmailChangeEmailInput,
  ): Promise<QueryResult<ResendEmailChangeEmailResult>> {
    await this.inited;

    return resendEmailChangeEmail(params, this.tokenStorage);
  }

  public async resendSignupEmail(
    params: ResendSignupEmailInput,
  ): Promise<QueryResult<ResendSignupEmailResult>> {
    await this.inited;

    return resendSignupEmail(params);
  }

  public async revertEmailChange(
    params: RevertEmailInput,
  ): Promise<QueryResult<RevertEmailResult>> {
    await this.inited;

    return revertEmailChange(params);
  }

  public async verifyEmail(
    params: VerifyEmailInput,
  ): Promise<QueryResult<VerifyEmailResult>> {
    await this.inited;

    return verifyEmail(params);
  }

  public isNativeApp(): boolean {
    return false;
  }

  public async updateCustomer(
    params: UpdateCustomerInput,
  ): Promise<QueryResult<UpdateCustomerResult>> {
    await this.inited;

    return updateCustomer(params, this.tokenStorage);
  }

  public async deleteCustomer(
    params: DeleteCustomerInput,
  ): Promise<QueryResult<DeleteCustomerResult>> {
    await this.inited;

    return deleteCustomer(params, this.tokenStorage);
  }

  public async sendDeleteCustomerEmail(
    params: SendDeleteCustomerEmailInput,
  ): Promise<QueryResult<SendDeleteCustomerEmailResult>> {
    await this.inited;

    return sendDeleteCustomerEmail(params, this.tokenStorage);
  }

  public isSupportedNativeLoginProvider(authProvider: AuthProvider): boolean {
    return false;
  }

  public isModuleInited(): boolean {
    return isModuleInited();
  }

  public async getToken(): Promise<string | null> {
    await this.inited;

    return this.tokenStorage.getCachedToken() || null;
  }

  public async resolveAuthMethod(
    provider: AuthProvider,
    action: 'login' | 'signup' | 'link',
  ): Promise<IResolveAuthMethodResult | undefined> {
    // social media not supported in NPM
    return undefined;
  }

  public async uploadAvatar(
    params: HTMLInputElement,
  ): Promise<QueryResult<UploadAvatarResult> | undefined> {
    // avatar upload not supported in NPM
    return undefined;
  }

  public async initRecaptchaV2(id: string) {
    await initRecaptchaV2(id);
  }

  public async getRecaptchaVersion(id: string) {
    return getRecaptchaVersion(id);
  }

  // get reCaptcha token
  public getReCaptchaToken(): any {
    // JSON.parse(JSON.stringify(o))
    const token = this.#reCaptchaToken;

    // remove token
    this.#reCaptchaToken = undefined;

    return token;
  }

  // Init recovering customer, revoke sessions, logout and require password change
  public async recoverCustomer(language: string): Promise<boolean> {
    await this.inited;

    const { data } = await recoverCustomer(language, this.tokenStorage);

    if (data?.success) {
      await this.logout();
    }

    return !!data?.success;
  }

  public async addPianoToken() {
    if (!ENABLE_PIANO_TOKEN_SWAP) {
      return false;
    }

    const token = await this.getToken();

    if (!token) {
      return false;
    }

    const pianoCookie = getLocalCookie(PIANO_USER_TOKEN_NAME);

    if (pianoCookie) {
      return false;
    }

    try {
      const result = await swapSessionToken(token);

      if (result.data && result.data.status && result.data.pianoAccessToken) {
        setPianoCookie(PIANO_USER_TOKEN_NAME, result.data.pianoAccessToken);
        setPianoCookie(PIANO_USER_SESSION_TOKEN_NAME, '1');

        return true;
      }
    } catch (e) {
      console.log('token swap failed', e);
    }

    console.log('token swap failed');

    return false;
  }

  public on(eventName: string, cb: (data: any) => void): void {}

  public off(eventName: string, cb?: (data: any) => void): void {}
}
