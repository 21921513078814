import { getCachedFacebookParams } from '../crossLocalStorage/authParamsStorage';
import loadAuthParams from '../initialization/loadAuthParams';
import { getOptions } from '../util/clientOptions';
import isBrowser from '../util/isBrowser';

import addScriptToBody from './addScriptToBody';

const initFB = (appId: string) => {
  FB.init({
    appId, // FB App ID
    cookie: false, // enable cookies to allow the server to access the session
    version: 'v18.0', // use graph api version 18.0 https://developers.facebook.com/docs/graph-api/changelog/
    xfbml: false, // parse social plugins on this page
  });
};

let initInstance: Promise<void>;

export const init = async () => {
  if (!initInstance) {
    initInstance = new Promise(async resolve => {
      await loadAuthParams();

      const params = getCachedFacebookParams(true);
      const appId = params?.appId || null;

      if (!appId) {
        return resolve();
      }

      if ((window as any).FB !== undefined) {
        initFB(appId);

        return resolve();
      }

      (window as any).fbAsyncInit = () => {
        initFB(appId);
        resolve();
      };

      const script = document.createElement('script');

      script.async = true;
      script.setAttribute(
        'src',
        `https://connect.facebook.net/${getOptions().facebookLocale}/sdk.js`,
      );
      addScriptToBody(script);
    });
  }

  return initInstance;
};

export const login = (options: object): Promise<string> | undefined => {
  if (!isBrowser()) {
    return;
  }

  return new Promise((resolve, reject) => {
    FB.login(response => {
      if (response.status !== 'connected') {
        // did not login
        reject();
      }

      resolve(response.authResponse.accessToken);
    }, options);
  });
};
